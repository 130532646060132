//
// footer.scss
//

.footer {
  bottom: 0;
  padding: 19px 15px 20px;
  position: absolute;
  right: 0;
  color: $gray-600;
  left: $leftbar-width;
  background-color: darken($body-bg, 2.5%);

  .footer-links {
    a {
      color: $gray-600;
      margin-left: 1.5rem;
      transition: all 0.4s;
      &:hover {
        color: $gray-900;
      }
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.footer-alt {
  left: 0 !important;
  text-align: center;
  background-color: transparent;
}

@include media-breakpoint-down(sm) {
  .footer {
    left: 0 !important;
    text-align: center;
  }
}
