.navbar_custom_ {
  background-color: #5a5353 !important;
  display: flex;
  margin: 0px;
  padding: 0px;
  height: 90px;
  .navbar_custom_b1 {
    flex: 1;
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-left: 10px;
    // background-color: #50bfce;
    justify-content: space-between;
    p {
      color: #fff;
      margin: 0px;
      font-size: 22px;
    }
    i {
      cursor: pointer;
      color: #fff;
    }
    // background-color: #50ce6b;
  }
  .navbar_custom_b2 {
    flex: 2;
    // background-color: #13b9e2;
    // padding: 10px 10px;
    align-self: center;
    nav {
      //   background-color: #3db65b;
      background-color: #191818;
      display: flex;
      border: 0.5px solid #cccccc;
      margin: 0px auto;
      border-radius: 25px;
      height: 60px;
      box-shadow: 5px 6px 9px 0px #000000a1;
      padding: 0px 40px;
      justify-content: space-between;
      align-items: center;

      .nav_link_ref {
        cursor: pointer;
        //flex: 1;
        //display: flex;
        // border: 1px solid;
        justify-content: center;
        align-items: center;
        margin-left:20px;

        &:hover {
          padding-bottom: 10px;
          transform: 1;
        }
      }

      .nav_link_dis {
        filter: opacity(0.5);
        cursor: not-allowed;
        &:hover {
          padding-bottom: 0px;
        }
      }

      .active_link {
        padding-bottom: 10px;
      }
    }
  }
  .navbar_custom_b3 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // background-color: #5113e2;

    .notif {
      margin-right: 20px;
    }

    .avatar {
      width: 60px;
      height: 60px;
      border: 1px solid;
      border-radius: 50%;
      margin: 0px 10px;
    }
  }

  .dropdown_lx {
    // background-color: aquamarine;
    min-width: 100px;
    padding: 10px 10px;
    div {
      // text-align: ;
      cursor: pointer;

      img {
        display: inline-block;
      }
    }
    // display: flex;
    // flex-direction: row;
  }
}
