.wrapper_2 {
  overflow: hidden;
  height: 100vh;
  overflow-y: auto;
}
.wrapper {
  border-radius: 0.25rem;

  max-width: calc(110vw - 3rem);
  max-height: calc(145vh - 3rem);
  width: 100vw;
  background-color: white;
  // overflow        : hidden;
  // height: 100vh;
  // overflow-y: auto;
  .ourTitle {
    height: 100px;
    display: flex;
    background-color: $primaryBookTableColor;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem;

    .text {
      font-size: 21px;
      margin: 0 auto;
      display: block;
      // color: white;
    }
    .subText {
      font-size: 1rem;
      margin: 0 auto;
      display: block;
      color: #000;
    }
  }

  .ourBody {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 1rem;
    margin-top: 5rem;
    .row-flex {
      display: flex;
      flex: 1;
      padding: 0.5rem;
      .item-flex {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.advancedSelector {
  height: 90px;
  background-color: white;
  margin-top: 16px;

  .Title {
    font-size: 13px;
  }

  .carousel {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrows {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 4px;
      border: 1px solid #00000047;
      cursor: pointer;
    }

    .carouselBodayNoScroll {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      position: relative;

      > div {
        width: 90px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #00000033;
        margin-left: 3px;
        transition: 0.4s;
        left: 0;
        position: relative;
        cursor: pointer;
        flex-grow: 1;

        &:hover {
          background-color: #ff63471c !important;
          color: black !important;
        }
      }
    }

    .carouselBoday {
      display: flex;
      flex-grow: 1;
      align-items: center;
      overflow: hidden;
      // justify-content: center;

      > div {
        width: 90px;
        height: 50px;

        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #00000033;
        margin-left: 3px;
        transition: 0.4s;
        left: 0;
        position: relative;
        cursor: pointer;

        &:hover {
          background-color: #ff63471c !important;
          color: black !important;
        }
      }
    }
  }
}

.toolsMenu {
  padding: 10px;
  &:hover {
    background-color: rgb(180, 179, 179) !important;
  }
}
