//
// vector-maps.scss
//

.jvectormap-label {
  border: none;
  background: $dark;
  color: $white;
  font-family: $font-family-secondary;
  font-size: $font-size-base;
  padding: 5px 8px;
}
