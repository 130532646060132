//
// badge.scss
//

.badge {
  color: $white;
}

.badge-light {
  color: $gray-800;
}
