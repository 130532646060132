//
// accordions.scss
//

.custom-accordion {
  .card {
    box-shadow: none;
    border: 1px solid $gray-100 !important;
  }
}
