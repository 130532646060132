.td_cust {
  text-align: left;
  font-size: 12px;
  text-transform: lowercase;
  font-weight: 400;

  &:first-letter {
    text-transform: uppercase;
  }
}

.label_resto {
  background-color: #6d757d;
  text-align: left;
  font-size: 12px;
  text-transform: lowercase;
  font-weight: 400;

  &:first-letter {
    text-transform: uppercase;
  }
}

.select50width {
  width: 50%;
  margin-left: 10px;
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown_content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown_content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.social_media_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .row {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    .subRow {
      display: flex;
      width: 100%;
      justify-content: space-around;
      flex: 1;
    }
  }
}
