//
// progress.scss
//

// Custom height

// Progress height small
.progress-sm {
  height: 5px !important;
}

// Progress height medium
.progress-md {
  height: 8px !important;
}

// Progress height large
.progress-lg {
  height: 12px !important;
}

// Progress height extra large
.progress-xl {
  height: 15px;
}

// progress

.progress {
  margin-top: 18px;
}

.progress-animated {
  animation-duration: 5s;
  animation-name: animationProgress;
  transition: 5s all;
}

@each $color, $value in $theme-colors {
  .progress-bar-alt-#{$color} {
    background-color: rgba(($value), 0.2);
  }
}

/* Progressbar Animated */
@-webkit-keyframes animationProgress {
  from {
    width: 0;
  }
}
@keyframes animationProgress {
  from {
    width: 0;
  }
}
