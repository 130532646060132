//@import "../../public/styles/variables";
//@import "../../public/styles/fonts";
//@import '~bulma/sass/grid/columns.sass';
$affair : red;
$jagger : red;

.tabs {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    ul.tabs_header {
        //background-color: black;
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;
        display: flex;
        width: 100%;
        overflow-x:auto;

        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */


        .active {
            //background: #000;
            color:$affair;
            //color: #0052A1;
            border-bottom: 4px solid $affair;

        }
        li {
            //flex: 1;
            text-align: center;
            display: inline-block;
            cursor: pointer;
            padding: 1rem;
            //@extend %Nexa-Bold-18px;
            color: $jagger;
            border-bottom: 4px solid transparent ;
            transition: 0.2s ease-out;
            &:not(:last-child) {
                border-right: none;
            }
        }
    }
    .tabs_body {
        //background-color: #ff4d56;
        text-align: center;
        padding: 3rem;
        //border: 1px solid #ccc;
        //border-top: none;
        flex: 1;
        //@include mobile {
        //    padding: 1rem;
        //}

    }
}
