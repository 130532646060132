.timeline-container {
  border: 1px solid black;
}
.timeline-item-el {
  // border: 1px solid black;
  width: 60px;
  padding: 20px;
  text-align: center;
  height: 1.8rem;
  line-height: 1.8rem;
  overflow: hidden;
  vertical-align: middle;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  // background: red;
  // border: 1px solid black;
}
.timeline-item-el-footer {
  // height: 1.8rem;
  cursor: pointer;
  border-right: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
}
.box {
  display: flex !important;
  // padding: 10px;
  // border: 1px solid black;
  // border: 1px solid black;
}
.box_ {
  display: flex !important;
  // padding: 10px;
  // border: 1px solid black;
  // border: 1px solid black;
  &:last-child {
    .item__ {
      background-color: lime;
      border: 2px solid;
    }
  }
}

// .box::before {
//     content: "<- ENNUYEUX !";
//     color: red;
//     width: 40px;
//     width: 40px;
//     left: -20px;
//     border: 1px solid black;
//     position: absolute;
//     z-index: 200;
// }

.container {
  padding: 20px;
  // border:dotted 1px;
  white-space: nowrap;
  overflow-x: auto;
}
.timeline-item-el-f-color1 {
  background-color: #f8f8f8;
}
.timeline-item-el-f-color2 {
  background-color: #ffffff;
}

.back-blue {
  background-color: #2c96e0 !important;
}
.back-red {
  background-color: #2786cc !important;
}

.add-pad {
  padding-right: 21px;
}

.container__ {
  padding-bottom: 13px;
  white-space: nowrap;
  overflow-x: auto;
}

.no-scroll-bar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: hidden !important;
}
