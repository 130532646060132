//
// authentication.scss
//

.authentication-bg.enlarged {
  min-height: 100px;
}

// authentication pages background
body.authentication-bg {
  // background-image: url("../../../images/big/bg1.jpg");
  // background-size: cover;
  // background-position: center;
  background-color: #cccccc;
}

.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

// Logout page
.logout-icon {
  width: 140px;
}
