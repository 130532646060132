//
// tables.scss
//

//Table centered (Custom)
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}
