//
// summernote.scss
//

@font-face {
  font-family: 'summernote';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/summernote.eot');
  src: url('../fonts/summernote.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/summernote.woff?') format('woff'),
    url('../fonts/summernote.ttf?') format('truetype');
}

.note-editor {
  &.note-frame {
    border: 1px solid $input-border-color;
    box-shadow: none;
    margin: 0;

    .note-statusbar {
      background-color: lighten($gray-200, 2%);
      border-top: 1px solid $gray-200;
    }

    .note-editable {
      border: none;
    }
  }
}

.note-status-output {
  display: none;
}

.note-editable {
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  padding: $input-padding-y $input-padding-x;

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.note-popover .popover-content .note-color .dropdown-menu,
.card-header.note-toolbar .note-color .dropdown-menu {
  min-width: 344px;
}

.note-toolbar {
  z-index: 1;
}
