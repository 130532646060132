
@mixin btn_control($hover_color) {
  cursor:pointer;
  border:1px solid black;
  padding: 10px;
  width:30px;
  height:30px;
  display:flex;
  justify-content:center;
  align-items:center;

  &:hover {
    background-color: $hover_color;
    color: white;
  }
}


.notification{
  text-align: left;
}

.selected{
  border: 1px solid #c91919
}

.contentListNotif{
  display: flex;
  flex-direction:column;
  overflow-y: auto;
  height:500px;
  padding-right:15px;
  .itemNotif{
    border-bottom:1px solid #dfdfdf;
    .date{
      display: flex;
      justify-content: flex-end;
      padding: 10px 0;
    }
  }

  .controlled{
    display: flex;
    justify-content:flex-end;
    padding-top: 10px;
    .delete_btn{
      @include btn_control($hover_color:#af5757);
      margin-right: 5px;
    }

    .reload{
      @include btn_control($hover_color:red);
    }

  }

}




