//
// taskboard.scss
//

.taskList {
  min-height: 40px;
  margin-bottom: 0;
  > li {
    background-color: $white;
    border: 1px solid $gray-300;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 3px;
  }
  .kanban-detail {
    margin-left: 35px;
    ul {
      li {
        a {
          display: inline-block;
          i {
            font-size: 16px;
            color: $gray-600;
            padding: 0px 5px;
            line-height: 32px;
          }
        }
      }
    }
  }
}

.task-placeholder {
  border: 1px dashed $gray-300 !important;
  background-color: $gray-200 !important;
  padding: 20px;
}

.gu-mirror {
  list-style: none;
}
