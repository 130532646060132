.test {
  margin: 15px;
  border: thin black solid;
}

.btns {
  margin: 0px 10px;
  border: 0px solid black;
  padding: 2px 20px;
  // border-radius: 5px;
  height: 37px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.LGFFFEEE {
  // background-image: linear-gradient(to bottom, #ffffff, #eee);
  background-color: #d0d1d2;
  // background-color: #14579b;
  display: flex;
  position: relative;
}

.tesrRRR {
  background-color: #d0d1d2;
  padding-top: 20px;
}

.careWalkIn {
  width: 11px;
  height: 11px;
  background: #56ccf2;
  display: inline-block;
  margin: auto 3px;
}

.carePhone {
  width: 11px;
  height: 11px;
  background: #42d479;
  display: inline-block;
  margin: auto 3px;
}

.careOnline {
  width: 11px;
  height: 11px;
  background: #fdd81f;
  display: inline-block;
  margin: auto 3px;
}

.ReservationTicket {
  height: 96px;
  // box-shadow           : 19px 20px 55px #00000021;
  box-shadow: 5px 5px 5px #00000021;
  border-left: 7px solid #35ca6e;
  margin: 8px 0px;
  display: grid;
  background-color: white;
  grid-template-columns: 6% 7% 13% 13% 15% 19% 12% 1% 15%;
}

%item-reservationTicket-top {
  background-color: #414344;
  color: black;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
%item-reservationTicket-bottom {
  color: black;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ReservationTicket2 {
  height: 96px;
  box-shadow: 19px 20px 55px #00000021;
  box-shadow: 5px 5px 5px #00000021;
  margin: 8px 0px;
  display: grid;
  background-color: rgb(248, 248, 248);
  grid-template-columns: 10% 20% 20% 23% auto;
  grid-template-rows: 50% auto;
  grid-column-gap: 0px;

  grid-template-areas:
    'progress name   phone  email rest'
    'time     couver1 couver2 couver3 rest1';
  // grid-template-columns: 6% 7% 13% 13% 15% 19% 12% 1% 15%;

  .item-rest {
    grid-area: rest;
    @extend %item-reservationTicket-top;
    justify-content: flex-end;

    .sp-icon-rest {
      // border: 1px solid black;
      padding: 5px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      cursor: pointer;
      i {
        font-size: 23px;
      }
    }
  }
  .item-rest1 {
    grid-area: rest1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .sp-icon-rest {
      // border: 1px solid black;
      padding: 5px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  .item-progress {
    grid-area: progress;
    @extend %item-reservationTicket-top;
  }
  .item-name {
    grid-area: name;
    @extend %item-reservationTicket-top;
  }
  .item-phone {
    grid-area: phone;
    @extend %item-reservationTicket-top;
  }
  .item-email {
    grid-area: email;
    @extend %item-reservationTicket-top;
  }
  .item-time {
    grid-area: time;
    @extend %item-reservationTicket-bottom;
  }
  .item-couver1 {
    grid-area: couver1;
    @extend %item-reservationTicket-bottom;
  }
  .item-couver2 {
    grid-area: couver2;
    @extend %item-reservationTicket-bottom;
  }
  .item-couver3 {
    grid-area: couver3;
    //font-weight: bold;
    @extend %item-reservationTicket-bottom;
  }
}

.my-ReservationModal {
  width: 420px;
  max-width: 420px;
}

.my-ReservationModal-Note {
  width: 50vw;
  max-width: 50vw;
}

.nav-link.active {
  background: #fff !important;
  border-bottom: 1px solid #fff !important;
  border-top: 2px solid $primary !important;

  &:hover {
    background: $primary !important;

    color: #fff !important;
  }
}

.tab-costom {
  background-color: transparent !important;
}

.nav-item {
  height: 3em;
  cursor: pointer;
  color: #333;
  background: #f2f2f2;
  border: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  border-radius: 3px 3px 0 0;
  font-size: 0.8rem;
  outline: none;

  &:hover {
    background: $primary !important;
    color: #fff !important;
  }
}

.Savebutton {
  display: inline-block;
  padding: 0.3em 0.8em;
  font-family: inherit;
  font-size: 1em;
  background: $primary;
  border: 1px solid $primary;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  height: auto;
  transition: none;
}
.Settingsbutton {
  display: inline-block;
  padding: 0.3em 0.8em;
  font-family: inherit;
  font-size: 1em;
  background: #333;
  border: 1px solid #333;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  height: auto;
  transition: none;
}
