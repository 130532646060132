//
// email.scss
//
.inbox-app-main {
  margin-left: -20px;
}

.circle-icon {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 26px;
  border: 2px solid $gray-600;
  border-radius: 100px;
  font-size: 14px;
  color: $gray-600;
  cursor: pointer;
  display: block;
  float: left;
  &:hover {
    color: darken($gray-600, 15%);
    border-color: darken($gray-600, 15%);
  }
}

.circle-icon {
  &.small {
    height: 25px;
    width: 25px;
    line-height: 21px;
    font-size: 11px;
  }
  &.red {
    color: $danger;
    border-color: $danger;
    &:hover {
      color: darken($danger, 30%);
      border-color: darken($danger, 30%);
    }
  }
}

.checkbox-wrapper-mail {
  cursor: pointer;
  height: 20px;
  width: 20px;
  position: relative;
  display: inline-block;
  box-shadow: inset 0 0 0 1px $gray-600;
  border-radius: 1px;
  input {
    opacity: 0;
    cursor: pointer;
    display: none;
    &:checked ~ label {
      opacity: 1;
    }
  }
  label {
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    cursor: pointer;
    background: $gray-600;
    opacity: 0;
    margin-bottom: 0px !important;
    transition-duration: 0.05s;
    &:active {
      background: $gray-700;
    }
  }
}

#sidebar {
  position: fixed;
  height: 100%;
  width: 240px;
  transition-duration: 0.3s;
  z-index: 5;
  color: $dark;
  li {
    a {
      color: $dark;
    }
  }
  .menu-segment {
    padding: 0 50px;
    ul,
    li {
      margin: 0;
      padding: 0;
    }
    .ball {
      &.pink {
        border-color: $pink;
      }
      &.green {
        border-color: $success;
      }
      &.blue {
        border-color: $primary;
      }
    }
    li {
      &.title {
        text-transform: uppercase;
        font-weight: 600;
        padding: 10px 0;
        color: $gray-700;
        .icon {
          float: right;
        }
      }
    }
  }
  .menu-segment li.active a,
  .menu-segment li.active a:hover {
    color: $danger;
    font-weight: 600;
  }
  .menu-segment {
    li {
      a {
        display: block;
        margin: 0 -50px;
        padding: 10px 50px;
        &:hover {
          background: rgba($gray-400, 0.3);
        }
      }
      .italic-link {
        font-style: italic;
        font-weight: 600;
      }
    }
    .labels,
    .chat {
      li {
        a {
          span {
            display: block;
            border: 5px solid $danger;
            border-radius: 100px;
            margin-top: 6px;
          }
        }
      }
    }
    .labels,
    .chat {
      &.labels {
        li {
          a {
            span {
              float: right;
            }
          }
        }
      }
    }
    .labels,
    .chat {
      &.chat {
        li {
          a {
            span {
              float: left;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .chat {
      li {
        a {
          padding-left: 50px;
        }
      }
    }
  }
  .separator {
    margin: 30px 35px;
    height: 1px;
    background: rgba($gray-600, 0.5);
  }
  .bottom-padding {
    height: 100px;
  }
  > .nano-pane {
    background: transparent;
    width: 5px;
    .nano-slider {
      background: rgba($gray-600, 0.3);
    }
  }
}

#main {
  position: fixed;
  top: 72px;
  left: 490px;
  bottom: 0;
  right: 0;
  z-index: 4;
  transition-duration: 0.3s;
  background-color: $white;
  .overlay {
    position: absolute;
    top: 0;
    left: -5px;
    right: 0;
    bottom: 100%;
    background: rgba($dark, 0.6);
    z-index: 5;
    opacity: 0;
    transition-duration: 0s;
    transition-property: opacity;
  }
  .header {
    padding: 12px 25px;
    border-bottom: 1px solid rgba($gray-600, 0.3);
    overflow: hidden;
    .page-title {
      display: block;
      .sidebar-toggle-btn {
        width: 0;
        margin-top: 1px;
        padding: 11px 0 0 0;
        float: left;
        position: relative;
        display: block;
        cursor: pointer;
        transition-duration: 0.3s;
        transition-delay: 0.5s;
        opacity: 0;
        margin-right: 0;
        .line {
          height: 3px;
          display: block;
          background: $gray-400;
          margin-bottom: 4px;
          transition-duration: 0.5s;
          transition-delay: 0.5s;
        }
        .line-angle1 {
          width: 8px;
          margin: 0;
          position: absolute;
          top: 15px;
          left: -11px;
          transform: rotate(-60deg);
        }
        .line-angle2 {
          width: 8px;
          margin: 0;
          position: absolute;
          top: 21px;
          left: -11px;
          transform: rotate(60deg);
        }
      }
      .icon {
        font-size: 15px;
        margin-left: 20px;
        position: relative;
        top: -5px;
        cursor: pointer;
      }
    }
    .search-box {
      width: 180px;
      height: 40px;
      margin-left: 30px;
      position: relative;
      input {
        position: absolute;
        top: 0;
        transition-duration: 0.3s;
        right: 0;
        bottom: 0;
        width: 100%;
        padding: 0px 36px 0px 0px;
        margin: 0;
        text-indent: 15px;
        height: 38px;
        z-index: 2;
        outline: none;
        color: $gray-500;
        background: transparent;
        border: 2px solid $gray-100;
        border-radius: 5px;
        transition-timing-function: cubic-bezier(0.3, 1.5, 0.6, 1);
        &:focus {
          color: $dark;
          border-color: $gray-200;
        }
        &:focus ~ .icon {
          opacity: 1;
          z-index: 3;
          color: $success;
        }
      }
      .icon {
        transition-duration: 0.3s;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        text-align: center;
        line-height: 38px;
        z-index: 1;
        cursor: pointer;
        opacity: 0.5;
      }
    }
  }
  .action-bar {
    padding: 5px;
    overflow: hidden;
  }
  .message-list {
    display: block;
    padding-left: 0;
    li {
      position: relative;
      display: block;
      height: 50px;
      line-height: 50px;
      cursor: default;
      transition-duration: 0.3s;
      color: $dark;
      &:hover {
        background: rgba($gray-600, 0.15);
        transition-duration: 0.05s;
      }
      .mail-col {
        float: left;
        position: relative;
      }
      .mail-col-1 {
        width: 400px;
        .star-toggle,
        .checkbox-wrapper-mail,
        .dot {
          display: block;
          float: left;
        }
        .dot {
          border: 4px solid transparent;
          border-radius: 100px;
          margin: 22px 26px 0;
          height: 0;
          width: 0;
          line-height: 0;
          font-size: 0;
        }
        .checkbox-wrapper-mail {
          margin-top: 15px;
          margin-right: 10px;
        }
        .star-toggle {
          margin-top: 18px;
          font-size: 14px;
          margin-left: 5px;
        }
        .title {
          position: absolute;
          top: 0px;
          left: 140px;
          right: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .mail-col-2 {
        position: absolute;
        top: 0;
        left: 400px;
        right: 0;
        bottom: 0;
        .subject,
        .date {
          position: absolute;
          top: 0;
        }
        .subject {
          left: 0;
          right: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .date {
          right: 0;
          width: 200px;
          padding-left: 80px;
        }
      }
    }
    li {
      &.active,
      &.selected {
        background: rgba($gray-600, 0.15);
        transition-duration: 0.05s;
      }
    }
    li.active,
    li.active:hover {
      box-shadow: inset 3px 0 0 $primary;
    }
    li {
      &.unread {
        font-weight: $font-weight-medium;
        color: $gray-600;
      }
      &.blue-dot {
        .mail-col-1 {
          .dot {
            border-color: $primary;
          }
        }
      }
      &.orange-dot {
        .mail-col-1 {
          .dot {
            border-color: $warning;
          }
        }
      }
      &.green-dot {
        .mail-col-1 {
          .dot {
            border-color: $success;
          }
        }
      }
    }
  }
  .load-more-link {
    display: block;
    text-align: center;
    margin: 30px 0 50px 0;
  }
}

.show-main-overlay {
  #main {
    .overlay {
      opacity: 1;
      bottom: 0;
      transition-duration: 0.5s;
    }
  }
}

.show-sidebar {
  #main {
    .header {
      .page-title {
        .sidebar-toggle-btn {
          transition-delay: 0s;
          .line-angle1 {
            transform: rotate(-120deg);
          }
          .line-angle2 {
            transform: rotate(120deg);
          }
        }
      }
    }
  }
}

#main {
  #main-nano-wrapper {
    position: absolute;
    top: 100px;
    bottom: 0;
    height: auto;
  }
}

#message {
  position: fixed;
  top: 72px;
  left: 60%;
  bottom: 0;
  width: 40%;
  z-index: 5;
  transform: translateX(200%);
  transition-duration: 0.5s;
  padding: 20px 30px;
  background: $white;
  box-shadow: 0 0px 77px 0 rgba($dark, 0.08),
    0 1px 0px 0 rgba($dark, 0.02);
  .header {
    padding-bottom: 20px;
    border-bottom: 2px solid rgba($gray-600, 0.2);
    .page-title {
      display: block;
      float: none;
      margin-bottom: 15px;
      line-height: 40px;
      .icon {
        margin-top: 4px;
        margin-right: 10px;
      }
    }
    .grey {
      margin-left: 10px;
      color: $gray-500;
    }
  }
  .message-container {
    padding: 0 30px;
    li {
      padding: 25px;
      border: 1px solid rgba($dark, 0.15);
      background: $white;
      margin: 0 0 30px 0;
      position: relative;
      .details {
        padding-bottom: 20px;
        border-bottom: 1px solid rgba($dark, 0.1);
        margin-bottom: 30px;
        overflow: hidden;
        .left {
          float: left;
          font-weight: 600;
          color: $gray-500;
          transition-duration: 0.3s;
          .arrow {
            display: inline-block;
            position: relative;
            height: 2px;
            width: 20px;
            background: rgba($dark, 0.15);
            vertical-align: top;
            margin: 10px 20px 0 15px;
            border: 0px solid rgba($dark, 0.15);
            transition-duration: 0.3s;
            &:after {
              position: absolute;
              top: -4px;
              left: 100%;
              height: 0;
              width: 0;
              border: inherit;
              border-width: 7px;
              border-style: solid;
              content: '';
              border-right: 0;
              border-top-color: transparent;
              border-bottom-color: transparent;
              border-top-width: 5px;
              border-bottom-width: 5px;
            }
          }
        }
        .right {
          float: right;
          color: $gray-500;
        }
      }
      .message {
        margin-bottom: 40px;
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &:hover {
        .tool-box {
          .red-hover {
            color: $danger;
            border-color: $danger;
            &:hover {
              color: darken($danger, 20%);
              border-color: darken($danger, 20%);
            }
          }
        }
      }
      .tool-box {
        position: absolute;
        bottom: 0;
        right: 0;
        border: 0px solid $gray-200;
        border-top-width: 1px;
        border-left-width: 1px;
        padding: 8px 10px;
        transition-duration: 0.3s;
        a {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      &:hover {
        .details {
          .left {
            .arrow {
              background: $success;
              border: 0px solid $success;
              &.orange {
                background: $warning;
                border: 0px solid $warning;
              }
            }
          }
        }
      }
    }
  }
  #message-nano-wrapper {
    position: absolute;
    top: 130px;
    bottom: 0;
    height: auto;
    left: 0;
    right: 0;
    width: auto;
  }
}

.show-message {
  #message {
    transform: none;
    transition-duration: 0.3s;
  }
  #main {
    margin-right: 40%;
  }
}

@media only screen and (min-width: 1499px) {
  #main {
    .overlay {
      display: none;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .show-message {
    #main {
      margin-right: 0;
    }
    #message {
      left: 50%;
      width: 50%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  #main {
    left: 340px;
  }
  .show-sidebar {
    #sidebar {
      transform: none;
    }
    #main {
      transform: translateX(250px);
    }
  }
  .show-message {
    #main {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  #sidebar {
    transform: translateX(-100%);
  }
  #main {
    left: 0;
    box-shadow: none;
    .header {
      .page-title {
        .sidebar-toggle-btn {
          margin-right: 20px;
          opacity: 1;
          width: 20px;
          margin-left: 10px;
          .line {
            height: 2px;
          }
        }
      }
    }
  }
  .show-sidebar {
    #sidebar {
      transform: none;
    }
    #main {
      transform: translateX(250px);
    }
  }
  .show-message {
    #main {
      margin-right: 0;
    }
    #message {
      left: 20%;
      width: 80%;
    }
  }
}

@media only screen and (max-width: 600px) {
  #main {
    .header {
      .search-box {
        float: none;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
        margin-left: 0;
        input,
        input:focus {
          width: 100%;
        }
      }
    }
    .action-bar {
      float: none !important;
    }
    .action-bar {
      ul {
        margin-bottom: 0;
        text-align: center;
      }
    }
    #main-nano-wrapper {
      position: absolute;
      top: 150px;
      bottom: 0;
      height: auto;
    }
    .message-list {
      li {
        .mail-col-1 {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 120px;
          width: auto;
        }
        .mail-col-2 {
          right: 0;
          left: auto;
          width: 120px;
          .date {
            padding-left: 0;
            position: static;
          }
          .subject {
            display: none;
          }
        }
      }
    }
  }
}

/**
 * Nano scroll stuff
*/

.nano {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  > .nano-content {
    position: absolute;
    overflow: scroll;
    overflow-x: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &:focus {
      outline: none;
    }
    &:-webkit-scrollbar {
      visibility: hidden;
    }
  }
  > .nano-pane {
    background: rgba($gray-600, 0.1);
    position: absolute;
    width: 7px;
    right: 8px;
    top: 8px;
    bottom: 8px;
    visibility: hidden \9;
    /* Target only IE7 and IE8 with this hack */
    opacity: 0.01;
    border-radius: 5px;
    transition: 0.3s;
    > .nano-slider {
      background: rgba($gray-600, 0.35);
      position: relative;
      border-radius: 3px;
      transition: 0.3s;
      transition-property: background;
    }
    &:hover,
    &.active {
      .nano-slider {
        background: $gray-300;
      }
    }
  }
}

.has-scrollbar {
  > .nano-content {
    &:-webkit-scrollbar {
      visibility: visible;
    }
  }
}

.nano:hover > .nano-pane,
.nano-pane.active,
.nano-pane.flashed {
  visibility: visible \9;
  /* Target only IE7 and IE8 with this hack */
  opacity: 0.99;
}
