//
// buttons.scss
//

.btn {
  color: $white;

  .mdi {
    &:before {
      margin-top: -1px;
    }
  }
}

.btn-rounded {
  border-radius: 2em;
}

//
// light button
//
.btn-light,
.btn-white {
  color: $gray-900;
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  background-color: transparent;
  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }
  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }
  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  } // No need for an active state here
}
//
// Alternate buttons
//
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Button labels
//

.btn-label {
  margin: -0.55rem 0.9rem -0.55rem -0.9rem;
  padding: 0.6rem 0.9rem;
  background-color: rgba($gray-900, 0.1);
}

.btn-label-right {
  margin: -0.55rem -0.9rem -0.55rem 0.9rem;
  padding: 0.6rem 0.9rem;
  background-color: rgba($gray-900, 0.1);
}

//
// Button Extra Small Size
//

.btn-xs {
  padding: 0.2rem 0.6rem;
  font-size: 0.75rem;
  border-radius: 0.15rem;
}

// btn bordered

@mixin button-bordred-variant($bg) {
  background-color: $bg;
  color: $white !important;
  border-bottom: 2px solid darken($bg, 7%);
}

@each $color, $value in $theme-colors {
  .btn-bordred-#{$color} {
    @include button-bordred-variant($value);
  }
}

// btn transparent

@mixin button-lighten-variant($bg) {
  border: 1px solid rgba($bg, 0.2) !important;
  background-color: rgba($bg, 0.25) !important;
  color: $bg !important;
}

@each $color, $value in $theme-colors {
  .btn-lighten-#{$color} {
    @include button-lighten-variant($value);
  }
}

.btn-not-background {
  background-color: #c1464600 !important;
  border: aliceblue !important;
  color: black !important;
  i {
    font-size: 20px;
  }
  &:hover {
    background-color: #c1464600 !important;
    color: black !important;
    box-shadow: none !important;
  }
  &:active {
    background-color: #c1464600 !important;
    color: black !important;
    box-shadow: none !important;
  }
  &:focus {
    background-color: #c1464600 !important;
    color: black !important;
    box-shadow: none !important;
  }
}
