//
// authentication.scss
//

.table-map-container {
  display: grid;
  width: calc(100vw - 400px);
  grid-template-columns: repeat(7, calc((100vw - 400px) / 7));
  grid-template-rows: repeat(7, calc((100vw - 400px) / 7));
  justify-content: center;
  margin: 'auto';
  justify-items: center;
}

.konvajs-content {
  background-color: #fff;
}
