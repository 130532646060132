@keyframes ldio-ois4qq7uijf {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-ois4qq7uijf div {
  box-sizing: border-box !important;
}
.ldio-ois4qq7uijf > div {
  position: absolute;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  border: 16px solid #000;
  border-color: #1d0e0b transparent #1d0e0b transparent;
  animation: ldio-ois4qq7uijf 1s linear infinite;
}
.ldio-ois4qq7uijf > div:nth-child(2) {
  border-color: transparent;
}
.ldio-ois4qq7uijf > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-ois4qq7uijf > div:nth-child(2) div:before,
.ldio-ois4qq7uijf > div:nth-child(2) div:after {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 48px;
  background: #1d0e0b;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #1d0e0b;
}
.ldio-ois4qq7uijf > div:nth-child(2) div:after {
  left: -16px;
  top: 48px;
  box-shadow: 128px 0 0 0 #1d0e0b;
}
.loadingio-spinner-dual-ring-uqjlfkebs99 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  //background: #f1f2f3;
}
.ldio-ois4qq7uijf {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ois4qq7uijf div {
  box-sizing: content-box;
}
